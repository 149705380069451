import React from 'react';
import { graphql } from 'gatsby';

import {
  Box,
  Text,
  Link,
  Header,
  InlineList,
  InlineListItem,
} from '../components/core';

const Footer = ({ px = 3, py = 5, mx = 'auto' }) => {
  return (
    <Box px={px} py={py} mx={mx}>
      <Text textAlign={'center'}>
        <InlineList>
          <InlineListItem>
            <Link to="mailto:peter.beshai@gmail.com">
              peter.beshai@gmail.com
            </Link>
          </InlineListItem>
          <InlineListItem>
            <Link to="https://twitter.com/pbesh">Twitter</Link>
          </InlineListItem>
          <InlineListItem>
            <Link to="https://twitch.tv/pbeshai">Twitch</Link>
          </InlineListItem>
          <InlineListItem>
            <Link to="https://github.com/pbeshai">GitHub</Link>
          </InlineListItem>
          <InlineListItem>
            <Link to="https://instagram.com/pbeshasketch">Instagram</Link>
          </InlineListItem>
          <InlineListItem>
            <Link to="https://www.linkedin.com/in/pbeshai">LinkedIn</Link>
          </InlineListItem>
        </InlineList>
      </Text>
    </Box>
  );
};

export default Footer;
