import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../utils/GlobalStyle';
import { Link as GatsbyLink } from 'gatsby';
import { theme } from '../utils/style';
import Nav from './Nav';
import Footer from './Footer';

import '../utils/prism-theme.css';
import { Box, Text, Link, Flex } from './core';

const defaultDescription =
  'Peter Beshai makes usable tools and visualizations with code.';
const keywords =
  'dataviz, data visualization, creative coding, generative art, nba, basketball';

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : 'https://peterbeshai.com';

const Layout = ({
  children,
  hideNavTitle,
  hideNav,
  pageTitle,
  description = defaultDescription,
  metaImage,
  slug = '',
}) => {
  const baseTitle = 'Peter Beshai';
  const fullTitle = pageTitle ? `${pageTitle} - ${baseTitle}` : baseTitle;

  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet title={fullTitle}>
        <link
          href="https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,400i,500,600,700"
          rel="stylesheet"
        />
        <html lang="en" />
        <meta name="description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="twitter:site" content="@pbesh" />
        <meta name="twitter:creator" content="@pbesh" />
        <meta property="og:title" content={pageTitle ? pageTitle : baseTitle} />
        <meta
          property="twitter:title"
          content={pageTitle ? pageTitle : baseTitle}
        />
        <meta property="og:url" content={`${baseUrl}${slug}`} />
        {metaImage && (
          <meta property="og:image" content={`${baseUrl}${metaImage}`} />
        )}
        {metaImage && (
          <meta name="twitter:image" content={`${baseUrl}${metaImage}`} />
        )}
      </Helmet>
      <ThemeProvider theme={theme}>
        <div>
          {!hideNav ? (
            <Flex alignItems="center" justifyContent="center" my={3}>
              <Text
                bg="black"
                color="white"
                px={2}
                as={GatsbyLink}
                to="/"
                css={css`
                  border-radius: 5px;
                  box-shadow: 3px 4px ${(props) => props.theme.colors.cyan[4]};
                  text-decoration: none;
                  transition: box-shadow 200ms;
                  &:hover {
                    box-shadow: 5px 6px ${(props) => props.theme.colors.cyan[4]};
                  }
                  &:active {
                    box-shadow: 2px 3px ${(props) => props.theme.colors.cyan[4]};
                  }
                `}
                fontSize={1}
                fontWeight={700}
              >
                Peter Beshai
              </Text>
            </Flex>
          ) : null}
          {children}
          <Footer />
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
