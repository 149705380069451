import React from 'react';
import { graphql } from 'gatsby';

import {
  Box,
  Text,
  Link,
  Header,
  InlineList,
  InlineListItem,
  Flex,
  Grid,
} from '../components/core';

const Nav = ({ showTitle, hideHome }) => {
  // we use external links so the # works properly on the home page.
  // but on other pages, use normal js routing links for better ux.
  // lazily check if home page by saying we aren't showing the title... sry
  const isExternalLinks = !showTitle;
  return (
    <div className="grid grid-cols-2">
      {!hideHome && (
        <div>
          <Link to="/" activeClassName="active">
            Home
          </Link>
        </div>
      )}
      <div>
        <Link
          external={isExternalLinks}
          to="/#writing"
          activeClassName="active"
        >
          Writing
        </Link>
      </div>
      <div>
        <Link
          external={isExternalLinks}
          to="/#projects"
          activeClassName="active"
        >
          Projects
        </Link>
      </div>
      <div>
        <Link
          external={isExternalLinks}
          to="/#experiments"
          activeClassName="active"
        >
          Experiments
        </Link>
      </div>
      <div>
        <Link
          external={isExternalLinks}
          to="/#open-source"
          activeClassName="active"
        >
          Open Source
        </Link>
      </div>
    </div>
  );
};

export default Nav;
